<h2>Image generation</h2>

<!--Allow user to change the font size using input-->
<div class="row">
  <div class="col-5">
    <nz-form-item>
      <nz-form-label>Pad left</nz-form-label>
      <input
        [(ngModel)]="padLeft"
        nz-input
        placeholder="Font size"
        type="number"
      />
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Pad top</nz-form-label>
      <input
        [(ngModel)]="padTop"
        nz-input
        placeholder="Font size"
        type="number"
      />
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Font size</nz-form-label>
      <input
        [(ngModel)]="fontSize"
        nz-input
        placeholder="Font size"
        type="number"
      />
    </nz-form-item>
    <nz-form-item>
      <textarea
        (blur)="drawText()"
        [(ngModel)]="quizContent"
        nz-input
        placeholder="Input"
        rows="10"
      ></textarea>
    </nz-form-item>
  </div>

  <div class="col-7">
    <div id="quiz-canvas-container">
      <canvas height="720" id="quiz-image-canvas" width="1280"></canvas>
    </div>
  </div>
</div>

<div class="row">
  <button (click)="generateImage()" nz-button nzType="primary">Generate</button>
</div>
