import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'ukata-image-generation',
  templateUrl: './image-generation.component.html',
  styleUrls: ['./image-generation.component.scss'],
  imports: [NzFormModule, FormsModule, NzInputModule, NzButtonModule],
  standalone: true,
})
export class ImageGenerationComponent implements OnInit, AfterViewInit {
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  quizContent: string = '';
  fontSize: number = 45;
  padTop: number = 150;
  padLeft: number = 100;
  backgroundImage: HTMLImageElement = new Image();

  ngOnInit() {}

  ngAfterViewInit() {
    this.canvas = document.getElementById(
      'quiz-image-canvas',
    ) as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d');

    // Create an image element for the background image
    this.backgroundImage.src = 'assets/images/ukata-background.png';
    // Wait for the background image to load
    const globalCtx = this.ctx;
    const globalBackgroundImage = this.backgroundImage;
    this.backgroundImage.onload = function () {
      // Draw the background image
      // @ts-ignore
      globalCtx.drawImage(globalBackgroundImage, 0, 0);
    };
  }

  drawText() {
    if (!this.ctx) {
      return;
    }
    //clear canvas virst
    this.ctx.clearRect(
      0,
      0,
      this.canvas?.width as number,
      this.canvas?.height as number,
    );
    this.drawBackground();
    this.ctx.fillStyle = '#fff';
    this.ctx.font = this.fontSize + 'px Noto Sans';
    const lines = this.quizContent.split('\n');
    for (let i = 0; i < lines.length; i++) {
      this.ctx.fillText(
        lines[i],
        this.padLeft,
        this.padTop + i * this.fontSize * 1.5,
      );
    }
  }

  generateImage() {
    if (this.canvas == null || this.ctx == null) {
      return;
    }
    // Export the canvas as a PNG image
    const image = this.canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download =
      'ukata.io-quiz-' +
      new Date().toLocaleTimeString() +
      '-' +
      Math.floor(Math.random() * 100) +
      '.png';
    link.click();
  }

  private drawBackground() {
    this.ctx?.drawImage(this.backgroundImage, 0, 0);
  }
}
